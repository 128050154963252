<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-header">
      <div class="advanced-search">
        <div class="advanced-search-item pagination-select">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>

        <div class="advanced-search-item" style="width: 100%">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>

        <div
          v-if="
            !filterOrganization &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          "
          class="advanced-search-item"
        >
          <organization-selector
            @organizationChanged="
              (organizationId) => (selectedOrganization = organizationId)
            "
          />
        </div>

        <div
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          class="advanced-search-item"
        >
          <locations-selector
            :organization="selectedOrganization"
            @locationsChanged="(locations) => (selectedLocations = locations)"
          />
        </div>

        <!-- status -->
        <div class="advanced-search-item">
          <el-select
            class="select-primary"
            v-model="status"
            :placeholder="$t('COMMON.STATUS')"
            :disabled="filterStatus != null"
          >
            <el-option class="select-primary" label="" value="" />

            <el-option
              class="select-primary"
              :label="$t('COMMON.DRAFT')"
              :value="VEHICLE_STATUS_DRAFT"
            />

            <el-option
              class="select-primary"
              :label="$t('COMMON.COMPLETED')"
              :value="VEHICLE_STATUS_COMPLETED"
            />
          </el-select>
        </div>

        <!-- is validated -->
        <div class="advanced-search-item">
          <el-select
            class="select-primary"
            v-model="isValidated"
            :placeholder="$t('VEHICLES.VALIDATION_STATUS')"
            :disabled="filterIsValidated != null"
          >
            <el-option class="select-primary" label="" :value="null" />

            <el-option
              class="select-primary"
              :label="$t('COMMON.YES')"
              :value="1"
            />

            <el-option
              class="select-primary"
              :label="$t('COMMON.NO')"
              :value="0"
            />
          </el-select>
        </div>

        <!-- auction status -->
        <div class="advanced-search-item">
          <el-select
            class="select-primary"
            v-model="auctionStatus"
            :placeholder="$t('VEHICLES.AUCTION_STATUS')"
            :disabled="filterAuctionStatus != null"
          >
            <el-option class="select-primary" label="" :value="null" />

            <el-option
              class="select-primary"
              :label="$t('VEHICLES.AUCTION_STATUS_SOON')"
              :value="VEHICLE_AUCTION_STATUS_SOON.toLowerCase()"
            />

            <el-option
              class="select-primary"
              :label="$t('VEHICLES.AUCTION_STATUS_ONGOING')"
              :value="VEHICLE_AUCTION_STATUS_ONGOING.toLowerCase()"
            />

            <el-option
              class="select-primary"
              :label="$t('VEHICLES.AUCTION_STATUS_COMPLETED')"
              :value="VEHICLE_AUCTION_STATUS_COMPLETED.toLowerCase()"
            />

            <el-option
              class="select-primary"
              :label="$t('VEHICLES.AUCTION_WON')"
              value="won"
            />

            <el-option
              class="select-primary"
              :label="$t('VEHICLES.AUCTION_LOST')"
              value="lost"
            />
          </el-select>
        </div>

        <!-- auction type -->
        <div class="advanced-search-item">
          <AuctionTypeSelector
            :value="auctionType"
            multiple
            :disabled="filterAuctionType != null"
            @auctionTypeChanged="(type) => (auctionType = type)"
          />
        </div>

        <!-- auction remaining time -->
        <div class="advanced-search-item">
          <el-select
            class="select-primary"
            :value="auctionRemainingTime"
            :placeholder="$t('VEHICLES.REMAINING_TIME')"
            @change="
              (time) => {
                auctionStatus = 'ongoing';

                auctionRemainingTime = time;
              }
            "
          >
            <el-option class="select-primary" label="" value="" />

            <el-option class="select-primary" label="4h" :value="4" />

            <el-option class="select-primary" label="24h" :value="24" />

            <el-option class="select-primary" label="48h" :value="48" />

            <el-option class="select-primary" label="72h" :value="72" />
          </el-select>
        </div>

        <!-- order status -->
        <div class="advanced-search-item">
          <el-select
            class="select-primary"
            v-model="orderStatus"
            :placeholder="$t('VEHICLES.ORDER_STATUS')"
          >
            <el-option class="select-primary" label="" value="" />

            <el-option
              class="select-primary"
              :label="$t('VEHICLES.WAITING_BUYER_PAYMENT')"
              value="WAITING_BUYER_PAYMENT"
            />
            <el-option
              class="select-primary"
              :label="$t('VEHICLES.UNDELIVERED')"
              value="UNDELIVERED"
            />
            <el-option
              class="select-primary"
              :label="$t('VEHICLES.DELIVERED')"
              value="DELIVERED"
            />
          </el-select>
        </div>

        <!-- purchases payment period -->
        <div class="advanced-search-item">
          <el-select
            class="select-primary"
            :value="purchasesPaymentPeriod"
            :placeholder="$t('VEHICLES.PURCHASE_PERIOD')"
            @change="
              (period) => {
                purchasesPaymentPeriod = period;
              }
            "
          >
            <el-option class="select-primary" label="" value="" />

            <el-option
              class="select-primary"
              :label="$t('VEHICLES.LAST_MONTH')"
              value="1"
            />
            <el-option
              class="select-primary"
              :label="$t('VEHICLES.LAST_THREE_MONTHS')"
              value="3"
            />
            <el-option
              class="select-primary"
              :label="$t('VEHICLES.LAST_YEAR')"
              value="12"
            />
          </el-select>
        </div>

        <!-- buyer payment method -->
        <div class="advanced-search-item">
          <el-select
            class="select-primary"
            v-model="buyerPaymentMethod"
            :placeholder="$t('PAYMENT_METHODS.PAYMENT_METHOD')"
          >
            <el-option class="select-primary" label="" value="" />

            <el-option class="select-primary" label="DPA" value="DPA" />

            <el-option class="select-primary" label="CHECK" value="CHECK" />
          </el-select>
        </div>

        <!-- rdprm status -->
        <div class="advanced-search-item">
          <el-select
            class="select-primary"
            v-model="rdprmStatus"
            :placeholder="$t('VEHICLES.RDPRM_STATUS')"
          >
            <el-option class="select-primary" label="" value="" />

            <el-option
              v-for="value in RDPRM_STATUS"
              :key="value"
              :value="value"
              :label="`${$t('VEHICLES.RDPRM_STATUS_' + value)}`"
            />
          </el-select>
        </div>

        <!-- vehicle_documents_status -->
        <div class="advanced-search-item">
          <el-select
            class="select-primary"
            v-model="documentsStatus"
            :placeholder="$t('VEHICLES.DOCUMENTS_STATUS')"
          >
            <el-option class="select-primary" label="" value="" />

            <el-option
              v-for="value in DOCUMENTS_STATUS"
              :key="value"
              :value="value"
              :label="`${$t('VEHICLES.DOCUMENTS_STATUS_' + value)}`"
            />
          </el-select>
        </div>

        <!-- reset -->
        <div class="advanced-search-item update">
          <base-button
            class="add bg-dark btn-icon"
            icon
            size="sm"
            @click="resetFilters"
          >
            <span class="btn-inner--icon">
              <i class="fa fa-undo"></i>
            </span>
            <span class="btn-inner--text"> {{ $t("COMMON.RESET") }} </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="!loading ? vehicles : []"
          row-key="id"
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" style="height: 100px; width: 100px" />
          </div>

          <el-table-column
            type="selection"
            width="55"
            v-if="toggleMultiSelect"
          />

          <el-table-column
            :label="$t('COMMON.CODE')"
            prop="code"
            sortable="custom"
            min-width="100"
          />

          <el-table-column :label="$t('COMMON.PICTURE')" min-width="100">
            <template v-slot="{ row }">
              <div class="user-image">
                <img
                  v-if="row.exterior_photos?.length > 0"
                  :src="row.exterior_photos[0]"
                  class="avatar rounded-circle"
                />
                <img
                  v-else
                  src="/img/camera.svg"
                  class="avatar rounded-circle p-2"
                />
              </div>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('VEHICLES.VEHICLE_AND_VIN')"
            min-width="180"
          >
            <template v-slot="{ row }">
              <div>
                {{ row.make }} {{ row.model }}
                {{ row.model_year }}
              </div>

              <div>
                {{ row.vin }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('VEHICLES.SERIES_AND_MOTOR')"
            min-width="180"
          >
            <template v-slot="{ row }">
              {{ row.series }} {{ row.style }} {{ row.engine }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.STATUS')"
            prop="status"
            min-width="180"
          >
            <badge
              slot-scope="{ row }"
              :type="
                row.status.toLowerCase() ===
                VEHICLE_STATUS_COMPLETED.toLowerCase()
                  ? 'success'
                  : 'warning'
              "
            >
              {{ $t(`COMMON.${row.status?.toUpperCase() ?? "DRAFT"}`) }}
            </badge>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.VALIDATED')"
            prop="is_validated"
            min-width="180"
          >
            <badge
              slot-scope="{ row }"
              :type="row.is_validated ? 'success' : 'warning'"
            >
              {{ row.is_validated ? $t(`COMMON.YES`) : $t(`COMMON.NO`) }}
            </badge>
          </el-table-column>

          <el-table-column :label="$t('COMMON.AUCTION')" min-width="150">
            <template v-slot="{ row }">
              <span v-if="row.is_validated">
                {{ $t(`AUCTIONS.AUCTION_TYPE_${row.auction_type}`) }} -
                {{
                  row.event?.start_at
                    ? $timeZoneDateFormat(row.event.start_at)
                    : $timeZoneDateFormat(row.auction_date)
                }}
              </span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('VEHICLES.BIDS_NUMBER')" min-width="150">
            <template v-slot="{ row }">
              <span>
                {{ row.bids.length }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('VEHICLES.AUCTION_STATUS')"
            min-width="250"
          >
            <template v-slot="{ row }">
              <div
                style="
                  padding: 4px 12px;
                  border-radius: 24px;
                  border: 1px solid #adb5bd;
                "
              >
                <span
                  style="width: 10px; height: 10px; border-radius: 100%"
                  :style="{
                    backgroundColor:
                      row.auction_status !==
                      VEHICLE_AUCTION_STATUS_COMPLETED.toLowerCase()
                        ? auctionStatusColor(row)
                        : postCompletedStatusColor(row),
                  }"
                ></span>
                <span
                  style="margin-left: 8px"
                  v-if="
                    row.auction_status !==
                    VEHICLE_AUCTION_STATUS_COMPLETED.toLowerCase()
                  "
                >
                  {{
                    $t(
                      `VEHICLES.AUCTION_STATUS_${row.auction_status.toUpperCase()}`
                    )
                  }}
                </span>

                <span style="margin-left: 8px" v-else>
                  {{ postCompletedLabel(row) }}
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.CREATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $timeZoneDateFormat(row.created_at) }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.UPDATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $timeZoneDateFormat(row.updated_at) }}
            </template>
          </el-table-column>

          <el-table-column
            fixed="right"
            min-width="120"
            v-if="!toggleMultiSelect"
          >
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_VEHICLES)"
              >
                <a
                  type="text"
                  @click="viewVehicle(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-expand-alt"></i>
                </a>
              </el-tooltip>

              <el-tooltip
                :content="$t('COMMON.EDIT')"
                placement="top"
                v-if="
                  $currentUserCan($permissions.PERM_EDIT_VEHICLES) &&
                  !row.is_validated
                "
              >
                <a
                  type="text"
                  @click="editVehicle(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-edit"></i>
                </a>
              </el-tooltip>

              <el-tooltip
                content="Delete"
                placement="top"
                v-if="
                  $currentUserCan($permissions.PERM_DELETE_VEHICLES) &&
                  !row.is_validated
                "
              >
                <a
                  type="text"
                  @click="deleteVehicle(row)"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-trash-alt"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}

        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>

      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import GallerySelector from "@/components/GallerySelector.vue";
import {
  VEHICLE_STATUS_COMPLETED,
  VEHICLE_STATUS_DRAFT,
  VEHICLE_AUCTION_STATUS_SOON,
  VEHICLE_AUCTION_STATUS_ONGOING,
  VEHICLE_AUCTION_STATUS_COMPLETED,
  RDPRM_STATUS,
  DOCUMENTS_STATUS,
  RDPRM_STATUS_PENDING,
  RDPRM_STATUS_INVALIDATED,
  DOCUMENTS_STATUS_PENDING,
  DOCUMENTS_STATUS_SEND,
} from "@/constants/vehicles";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { COLLECTE_STATUS_COLLECTED } from "@/constants/collectes";
import {
  PAYMENT_STATUS_AWAITING_DPA_CONTRACT_SIGNATURE,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_SENT,
  PAYMENT_STATUS_SENT_TO_BANK,
  PAYMENT_STATUS_DRAFT,
} from "@/constants/payments";
import { TRANSACTION_STATUS_PAIED } from "@/constants/transactions";
import AuctionTypeSelector from "@/components/AuctionTypeSelector.vue";

export default {
  name: "vehicle-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    LocationsSelector,
    AuctionTypeSelector,
    GallerySelector,
    flatPicker,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    toggleMultiSelect: {
      type: Boolean,
      default: false,
      description: "Toggle multi select",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterAuctionId: {
      type: String,
      default: null,
      description: "Auction id",
    },
    filterEventId: {
      type: String,
      default: null,
      description: "Event id",
    },
    filterStatus: {
      type: String,
      default: null,
      description: "Status",
    },
    filterIsValidated: {
      type: Number,
      default: null,
      description: "Is validated",
    },
    filterAuctionStatus: {
      type: String,
      default: null,
      description: "Auction Status",
    },
    filterAuctionType: {
      type: String,
      default: null,
      description: "Auction Type",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      VEHICLE_STATUS_COMPLETED,
      VEHICLE_STATUS_DRAFT,
      VEHICLE_AUCTION_STATUS_SOON,
      VEHICLE_AUCTION_STATUS_ONGOING,
      VEHICLE_AUCTION_STATUS_COMPLETED,
      COLLECTE_STATUS_COLLECTED,
      PAYMENT_STATUS_AWAITING_DPA_CONTRACT_SIGNATURE,
      PAYMENT_STATUS_PENDING,
      PAYMENT_STATUS_SENT,
      PAYMENT_STATUS_SENT_TO_BANK,
      PAYMENT_STATUS_DRAFT,
      TRANSACTION_STATUS_PAIED,
      RDPRM_STATUS,
      DOCUMENTS_STATUS,
      RDPRM_STATUS_PENDING,
      RDPRM_STATUS_INVALIDATED,
      DOCUMENTS_STATUS_PENDING,
      DOCUMENTS_STATUS_SEND,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      vehicles: [],
      loading: true,
      selectedOrganization: null,
      selectedLocations: null,
      status: this.filterStatus,
      isValidated: this.filterIsValidated,
      auctionStatus: this.filterAuctionStatus,
      auctionType: this.filterAuctionType,
      // ...
      auctionRemainingTime: null,
      orderStatus: null,
      transactionStatus: null,
      buyerPaymentStatus: null,
      purchasesPaymentPeriod: null,
      buyerPaymentMethod: null,
      sellerPaymentStatus: null,
      salesPaymentPeriod: null,
      sellerPaymentMethod: null,
      collecteStatus: null,
      rdprmStatus: null,
      documentsStatus: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterAuctionId: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterEventId: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterStatus: {
      handler() {
        this.status = this.filterStatus;
      },
    },
    filterIsValidated: {
      handler() {
        this.isValidated = this.filterIsValidated;
      },
    },
    filterAuctionStatus: {
      handler() {
        this.auctionStatus = this.filterAuctionStatus;
      },
    },
    filterAuctionType: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
    status: {
      handler: "getListDebounced",
      immediate: true,
    },
    isValidated: {
      handler: "getListDebounced",
      immediate: true,
    },
    auctionStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    auctionType: {
      handler: "getListDebounced",
      immediate: true,
      deep: true,
    },
    auctionRemainingTime: {
      handler: "getListDebounced",
      immediate: true,
    },
    // transactionStatus: {
    //   handler: "getListDebounced",
    //   immediate: true,
    // },
    // buyerPaymentStatus: {
    //   handler: "getListDebounced",
    //   immediate: true,
    // },
    // purchasesPaymentPeriod: {
    //   handler: "getListDebounced",
    //   immediate: true,
    // },
    buyerPaymentMethod: {
      handler: "getListDebounced",
      immediate: true,
    },
    // sellerPaymentStatus: {
    //   handler: "getListDebounced",
    //   immediate: true,
    // },
    // salesPaymentPeriod: {
    //   handler: "getListDebounced",
    //   immediate: true,
    // },
    // sellerPaymentMethod: {
    //   handler: "getListDebounced",
    //   immediate: true,
    // },
    // collecteStatus: {
    //   handler: "getListDebounced",
    //   immediate: true,
    // },
    orderStatus: {
      handler() {
        if (!this.orderStatus) {
          this.transactionStatus = null;
          this.buyerPaymentStatus = null;
          this.collecteStatus = null;
        } else if (this.orderStatus === "WAITING_BUYER_PAYMENT") {
          this.buyerPaymentStatus = "DRAFT";

          this.transactionStatus = null;
          this.purchasesPaymentPeriod = null;
          this.buyerPaymentMethod = null;
          this.collecteStatus = null;
        } else if (
          this.orderStatus === "UNDELIVERED" ||
          this.orderStatus === "DELIVERED"
        ) {
          this.collecteStatus = this.orderStatus;

          this.transactionStatus = null;
          this.buyerPaymentStatus = null;
          this.purchasesPaymentPeriod = null;
          this.buyerPaymentMethod = null;
        }

        this.getListDebounced();
      },
      immediate: true,
    },
    rdprmStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    documentsStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    resetFilters() {
      this.query = null;
      this.selectedOrganization = null;
      this.selectedLocations = null;
      this.status = null;
      this.isValidated = null;
      this.auctionStatus = null;
      this.auctionType = null;
      this.auctionRemainingTime = null;
      this.orderStatus = null;
      this.transactionStatus = null;
      this.buyerPaymentStatus = null;
      this.purchasesPaymentPeriod = null;
      this.buyerPaymentMethod = null;
      this.sellerPaymentStatus = null;
      this.salesPaymentPeriod = null;
      this.sellerPaymentMethod = null;
      this.collecteStatus = null;
      this.rdprmStatus = null;
      this.documentsStatus = null;
    },
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;

        const include = [
          "organization",
          "allowedLocations",
          "auction",
          "event",
          "bids",
          "lastTransaction.sellerInvoice.lastPayment",
          "lastTransaction.buyerInvoice.lastPayment.paymentMethod.contract",
          "lastTransaction.lastCollecte",
        ].join();

        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include,
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }

        if (this.filterAuctionId) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              auction: this.filterAuctionId,
            },
          };
        }

        if (this.filterEventId) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              event_id: this.filterEventId,
            },
          };
        }

        if (this.filterAuctionType) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              auction_type: this.filterAuctionType,
            },
          };
        }

        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }

        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        if (this.status) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.status,
            },
          };
        }

        if (this.isValidated != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              is_validated: this.isValidated,
            },
          };
        }

        if (this.auctionStatus != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              auction_status: this.auctionStatus,
            },
          };
        }

        if (this.auctionType != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              auction_type: this.auctionType,
            },
          };
        }

        if (this.auctionRemainingTime != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              auction_remaining_time: this.auctionRemainingTime,
            },
          };
        }

        if (this.transactionStatus != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              transaction_status: this.transactionStatus,
            },
          };
        }

        if (this.buyerPaymentStatus != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              buyer_payment_status: this.buyerPaymentStatus,
            },
          };
        }

        if (this.purchasesPaymentPeriod != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              purchases_payment_period: this.purchasesPaymentPeriod,
            },
          };
        }

        if (this.buyerPaymentMethod != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              buyer_payment_method: this.buyerPaymentMethod,
            },
          };
        }

        if (this.sellerPaymentStatus != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              seller_payment_status: this.sellerPaymentStatus,
            },
          };
        }

        if (this.salesPaymentPeriod != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              sales_payment_period: this.salesPaymentPeriod,
            },
          };
        }

        if (this.sellerPaymentMethod != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              seller_payment_method: this.sellerPaymentMethod,
            },
          };
        }

        if (this.collecteStatus != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              collecte_status: this.collecteStatus,
            },
          };
        }

        if (this.rdprmStatus != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              rdprm_status: this.rdprmStatus,
            },
          };
        }

        if (this.documentsStatus != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              vehicle_documents_status: this.documentsStatus,
            },
          };
        }

        await this.$store.dispatch("vehicles/list", params);
        this.vehicles = this.$store.getters["vehicles/list"];
        this.total = this.$store.getters["vehicles/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteVehicle(vehicle) {
      this.$emit("onDeleteVehicle", vehicle);
    },

    viewVehicle(vehicle) {
      this.$emit("onViewVehicle", vehicle);
    },

    editVehicle(vehicle) {
      this.$emit("onEditVehicle", vehicle);
    },

    validateVehicle(vehicle) {
      this.$emit("onValidateVehicle", vehicle);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    handleSelectionChange(rows) {
      this.selectedRows = rows;

      this.$emit("rowsSelectedChanged", rows);
    },

    postCompletedLabel(vehicle) {
      console.log(
        "vehicle",
        vehicle.code,
        vehicle.lastTransaction?.buyerInvoice?.lastPayment?.status
      );
      console.log(vehicle.lastTransaction?.sellerInvoice?.lastPayment?.status);
      console.log(vehicle.rdprm_status);
      console.log(vehicle.vehicle_documents_status);
      console.log(vehicle.lastTransaction?.lastCollecte?.status);

      if (!vehicle.is_validated) {
        return "";
      }

      if (vehicle.auction_status != "completed") {
        return "";
      }

      if (!vehicle.auction_won) {
        return "Véhicule non vendu";
      }

      if (!vehicle.lastTransaction) {
        return "En attente de traitement";
      }

      if (
        vehicle.lastTransaction?.buyerInvoice?.lastPayment?.status ==
        PAYMENT_STATUS_AWAITING_DPA_CONTRACT_SIGNATURE
      ) {
        return "En attente de signature DPA acheteur";
      }

      if (
        vehicle.lastTransaction?.buyerInvoice?.lastPayment?.status ==
        PAYMENT_STATUS_DRAFT
      ) {
        return "En attente de confirmation paiement acheteur";
      }

      if (
        [PAYMENT_STATUS_SENT, PAYMENT_STATUS_SENT_TO_BANK].includes(
          vehicle.lastTransaction?.buyerInvoice?.lastPayment?.status
        ) ||
        [
          PAYMENT_STATUS_PENDING,
          PAYMENT_STATUS_AWAITING_DPA_CONTRACT_SIGNATURE,
          PAYMENT_STATUS_SENT,
          PAYMENT_STATUS_SENT_TO_BANK,
        ].includes(vehicle.lastTransaction?.sellerInvoice?.lastPayment?.status)
      ) {
        return "Paiment en traitement";
      }

      if (vehicle.rdprm_status === RDPRM_STATUS_PENDING) {
        return "En attente validation RDPM";
      }

      if (vehicle.rdprm_status === RDPRM_STATUS_INVALIDATED) {
        return "RDPM non validé";
      }

      if (vehicle.vehicle_documents_status === DOCUMENTS_STATUS_PENDING) {
        return "En attente envoie des enregistrements";
      }

      if (vehicle.vehicle_documents_status === DOCUMENTS_STATUS_SEND) {
        return "En attente réception des enregistrements";
      }

      if (
        vehicle.lastTransaction?.lastCollecte?.status !==
        COLLECTE_STATUS_COLLECTED
      ) {
        return "En attente de collecte";
      }

      if (vehicle.lastTransaction?.status === TRANSACTION_STATUS_PAIED) {
        return "Véhicule vendu";
      }

      return "";
    },

    postCompletedStatusColor(vehicle) {
      if (vehicle.auction_status != "completed") {
        return "";
      }

      if (!vehicle.auction_won) {
        return "oklch(0.577 0.245 27.325)";
      }

      if (
        vehicle.lastTransaction?.sellerInvoice?.lastPayment?.status ==
          PAYMENT_STATUS_PENDING ||
        vehicle.lastTransaction?.sellerInvoice?.lastPayment?.status ==
          PAYMENT_STATUS_AWAITING_DPA_CONTRACT_SIGNATURE ||
        vehicle.lastTransaction?.sellerInvoice?.lastPayment?.status ==
          PAYMENT_STATUS_SENT ||
        vehicle.lastTransaction?.sellerInvoice?.lastPayment?.status ==
          PAYMENT_STATUS_SENT_TO_BANK ||
        !vehicle.lastTransaction?.buyerInvoice?.lastPayment ||
        vehicle.lastTransaction?.buyerInvoice?.lastPayment?.status ==
          PAYMENT_STATUS_PENDING
      ) {
        return "oklch(0.681 0.162 75.834)";
      }

      if (
        vehicle.lastTransaction?.lastCollecte?.status !==
        COLLECTE_STATUS_COLLECTED
      ) {
        return "oklch(0.681 0.162 75.834)";
      }

      if (vehicle.lastTransaction?.status === TRANSACTION_STATUS_PAIED) {
        return "oklch(0.723 0.219 149.579)";
      }

      return "";
    },

    auctionStatusColor(vehicle) {
      const labels = {
        soon: "oklch(0.769 0.188 70.08)",
        ongoing: "oklch(0.723 0.219 149.579)",
        completed: "oklch(0.637 0.237 25.331)",
      };

      return labels[vehicle.auction_status] ?? labels["soon"];
    },
  },
};
</script>
