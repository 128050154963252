<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>

    <div v-if="vehicle" class="elite-tabs-wrapper">
      <div class="tabs-wrapper">
        <tabs
          fill
          class="flex-column flex-md-row"
          tabNavWrapperClasses="nav-wrapper tabs-wrapper-nav"
          tabNavClasses="nav elite-tabs"
          value="global"
        >
          <tab-pane title="global" id="1" :active="true">
            <span slot="title">
              <i class="ni ni-cloud-upload-96"></i>
              {{ $t("COMMON.GLOBAL") }}
            </span>
            <vehicle-view-global
              :vehicle="vehicle"
              @onEditVehicle="editVehicle"
              @onDeleteVehicle="deleteVehicle"
              @onValidateVehicle="validateVehicle"
              @onInvalidateVehicle="invalidateVehicle"
              @onCloseVehicleAuction="closeVehicleAuction"
              @onCancelVehicleSale="cancelVehicleSale"
              @onConfirmVehicleSale="confirmVehicleSale"
              @onReauctionVehicle="reauctionVehicle"
              @onChangeVehicleRdprmStatus="changeVehicleRdprmStatus"
              @onChangeVehicleDocumentsStatus="changeVehicleDocumentsStatus"
            />
          </tab-pane>

          <tab-pane title="pictures" id="2" :active="true">
            <span slot="title">
              <i class="fa fa-image"></i>
              {{ $t("COMMON.PICTURES") }}
            </span>
            <vehicle-view-images :vehicle="vehicle" />
          </tab-pane>

          <tab-pane title="bids" id="3" :active="true">
            <span slot="title">
              <i class="fa fa-image"></i>
              {{ $t("COMMON.BIDS") }}
            </span>
            <vehicle-view-bids :vehicle="vehicle" />
          </tab-pane>
          <tab-pane
            title="transaction"
            id="4"
            :active="true"
            v-if="transaction"
          >
            <span slot="title">
              <i class="ni ni-money-coins"></i>
              {{ $t("COMMON.TRANSACTION") }}
            </span>
            <vehicle-active-transaction
              :transaction="transaction"
              v-if="transaction.status != 'CANCELED'"
              @onCancelTransaction="cancelTransaction"
            />
          </tab-pane>
          <tab-pane title="bids" id="5" :active="true">
            <span slot="title">
              <i class="fa fa-image"></i>
              {{ $t("COMMON.TRANSACTIONS") }}
            </span>
            <vehicle-transaction-list-table :vehicle="vehicle.id" />
          </tab-pane>
          <tab-pane
            title="logs"
            id="999"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
          >
            <span slot="title">
              <i class="fa fa-file"></i>
              {{ $t("COMMON.LOGS") }}
            </span>
            <vehicle-view-logs :vehicle="vehicle" />
          </tab-pane>
        </tabs>
      </div>
    </div>
    <div
      v-if="showCreateTransactionModal"
      class="resizable-wrapper"
      v-bind:class="[showCreateTransactionModal ? 'show' : 'hide']"
    >
      <div class="resizable-wrapper-content" v-resizable-modal="'TRANSACTION'">
        <div class="resizable-wrapper-content-header">
          <h1>
            {{ $t("TRANSACTIONS.ADD_TRANSACTION") }}
          </h1>
          <button class="close" @click="closeCreateTransactionModal">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="resizable-wrapper-content-body">
          <add-transaction-component
            :vehicleId="vehicle.id"
            @onViewTransaction="openViewTransactionModal"
          />
        </div>
      </div>
    </div>
    <div
      v-if="showViewTransactionModal"
      class="resizable-wrapper"
      v-bind:class="[showViewTransactionModal ? 'show' : 'hide']"
    >
      <div class="resizable-wrapper-content" v-resizable-modal="'TRANSACTION'">
        <div class="resizable-wrapper-content-header">
          <h1>
            {{ $t("TRANSACTIONS.VIEW_TRANSACTION") }}
          </h1>
          <button class="close" @click="closeViewTransactionModal">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="resizable-wrapper-content-body">
          <view-transaction-component :transactionId="transaction.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import VehicleViewGlobal from "../partials/VehicleViewGlobal.vue";
import VehicleViewLogs from "../partials/VehicleViewLogs.vue";
import VehicleViewImages from "../partials/VehicleViewImages.vue";
import VehicleViewBids from "../partials/VehicleViewBids.vue";
import VehicleTransactionListTable from "../partials/VehicleTransactionListTable.vue";
import VehicleActiveTransaction from "../partials/VehicleActiveTransaction.vue";
import AddTransactionComponent from "../../TransactionManagement/components/AddTransactionComponent.vue";
import ViewTransactionComponent from "../../TransactionManagement/components/ViewTransactionComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    VehicleViewGlobal,
    VehicleViewLogs,
    VehicleViewImages,
    VehicleViewBids,
    AddTransactionComponent,
    ViewTransactionComponent,
    VehicleTransactionListTable,
    VehicleActiveTransaction,
  },

  mixins: [requestErrorMixin],

  props: {
    vehicleId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      vehicle: null,
      transaction: null,
      showCreateTransactionModal: false,
      showViewTransactionModal: false,
      loading: true,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        this.loading = true;
        await this.$store.dispatch("vehicles/get", this.vehicleId);
        this.vehicle = this.$store.getters["vehicles/vehicle"];
        let params = {
          filter: {
            vehicle: this.vehicle.id,
          },
        };
        await this.$store.dispatch("transactions/list", params);
        let transactions = this.$store.getters["transactions/list"];
        let transactionId = 0;
        transactions.forEach((value, index) => {
          if (value.status != "CANCELED") {
            transactionId = value.id;
          }
        });
        if (transactionId != 0) {
          await this.$store.dispatch("transactions/get", transactionId);
          this.transaction = this.$store.getters["transactions/transaction"];
        }
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    editVehicle(vehicle) {
      this.$emit("onEditVehicle", vehicle);
    },
    deleteVehicle(vehicle) {
      this.$emit("onDeleteVehicle", vehicle);
    },
    validateVehicle(vehicle) {
      this.$emit("onValidateVehicle", vehicle);
    },
    invalidateVehicle(vehicle) {
      this.$emit("onInvalidateVehicle", vehicle);
    },
    closeVehicleAuction(vehicle) {
      this.$emit("onCloseVehicleAuction", vehicle);
    },
    cancelVehicleSale(vehicle) {
      this.$emit("onCancelVehicleSale", vehicle);
    },
    confirmVehicleSale(vehicle) {
      this.$emit("onConfirmVehicleSale", vehicle);
    },
    cancelTransaction(transaction) {
      this.$emit("onCancelTransaction", transaction);
    },
    reauctionVehicle(vehicle) {
      this.$emit("onReauctionVehicle", vehicle);
    },
    changeVehicleRdprmStatus(vehicle) {
      this.$emit("onChangeVehicleRdprmStatus", vehicle);
    },
    changeVehicleDocumentsStatus(vehicle) {
      this.$emit("onChangeVehicleDocumentsStatus", vehicle);
    },
  },
};
</script>
